import React, { Component } from 'react';
import '../styles/About.css';
import FadeInSection from './FadeInSection';

class About extends Component {
    render() {
        return (
            <div id='about' className='chapter-wrapper text-wrapper'>
                <FadeInSection>
                    <div className='chapter-header header-font mellow-color'>
                        About
                    </div>
                    <div className='chapter-content about'>
                        Hi there, I'm Lukas Pohlman, CEO of Pohlmans Consulting! <br/>
                        <br/>
                        This is my website, and while it may showcase inconceivable talents as a frontend developer, my true
                        expertise lies in the realm of system development. In fact, not only are my talents as a system
                        developer humongous, I'm also very kind and humble. You see, I enjoy working with individuals
                        AND teams to create successful products and services! And I have the track record to prove it.
                        Just look at my years of experience providing high performance solutions to happy clients ranging
                        from small local businesses to multi-billion dollar companies.<br/>
                        <br/>
                        Are you looking for a man with expert capabilities? Look no further.
                    </div>
                </FadeInSection>
            </div>
        );
    }
}

export default About;